@charset "utf-8";

/*=================================
  common
=================================*/
body {
  @extend %font;
  position: relative;
  word-break: break-word;
}
.float-l {
  float: left;
}
.float-r {
  float: right;
}
.note {
  font-size: 1.5rem;
  display: block;
}
.is-sp-only {
  display: none!important;
}
.is-txt-center {
  text-align: center;
}
.is-txt-right {
  text-align: right;
}
.is-txt-note {
  font-size: 1.2rem;
  padding-left: 1em;
  text-indent: -1em;
  display: block;
}
.inner-wrap {
  max-width: $base-width;
  margin: 0 auto;
}
.cf:after {
  content:" ";
  display:block;
  clear:both;
}

/*共通レイアウト*/
.layout--2col {
  max-width: $base-width;
  margin: 0 auto;
  padding-bottom: 50px;
  display: flex;
  align-items: flex-start;
  &__cont {
    width: calc(100% - 340px);
    padding: 30px 0;
    border-top: 4px solid #ddd;
    position: relative;
    &::before {
      content: "";
      width: 28%;
      height: 4px;
      display: block;
      background: $color-theme;
      position: absolute;
      top: -4px;
      left: 0;
    }
  }
  &.cont--r {
    flex-direction: row-reverse;
    .layout--2col__cont {
      margin-left: 40px;
    }
  }
  &.cont--l {
    .layout--2col__cont {
      margin-right: 40px;
    }
  }
}
.mainWrap {
  &.under {
    background: #fff;
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    common
  =================================*/
  html,body {
    min-width: 320px;
  }
  .is-sp-only {
    display: block!important;
  }
  .is-pc-only {
    display: none!important;
  }
  .note {
    font-size: 1.1rem;
  }
  .spInner {
    width: calc(100% - 7%);
    margin-left: auto;
    margin-right: auto;
  }
  /*共通レイアウト*/
  .layout--2col {
    display: block;
    padding-bottom: 0;
    &__cont {
      width: 100%;
      padding: 25px 3%;
    }
    &.cont--r {
      .layout--2col__cont {
        margin-left: 0;
      }
    }
    &.cont--l {
      .layout--2col__cont {
        margin-right: 0;
      }
    }
  }
  .mainWrap {
    margin-top: 54px;
  }
}